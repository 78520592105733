import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import lodash from 'lodash';

import { PaginationEntity } from '@core/pagination/entity';
import NotificationsEntity from '@modules/notifications/notificationsEntity';
import { TariffAccessibilityEnum } from '@modules/tariffPackages/interface';
import { ViewModeEnum } from '@view/Devices/Pages/component/DeviceDetail/interface';

export const indexOfArrayObject = (array: any[], key: string, value: any) => {
  if (!Array.isArray(array)) {
    return;
  }
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key] === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const debounce = (callback: any, _delay: number) => {
  return lodash.debounce(callback, _delay);
};

export const onScrollBottom = (callBack: any) => {
  window.onscroll = function (event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      callBack(event);
    }
  };
};

export function roundToTwo(num: string) {
  return Number.parseFloat(num).toFixed(2);
}

export function roundToFour(num: string) {
  return Number.parseFloat(num).toFixed(4);
}

export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
  return [...arr].splice(start, end);
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie: string = decodeURIComponent(document.cookie) || '';
  if (decodedCookie == null || decodedCookie === '') {
    return '';
  }
  const cookieValue = decodedCookie
    .split('; ')
    ?.find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue || '';
};

export const toFirstUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const toFirstLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function sessionStorageSetItem(key: string, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function sessionStorageGetItem(key: string) {
  const dataJson = sessionStorage.getItem(key);
  if (dataJson != null) {
    return JSON.parse(dataJson);
  } else {
    return undefined;
  }
}
export const removeNullFields = values => {
  for (const key in values) {
    if (values[key] === null) {
      values[key] = undefined;
    }
  }
  return values;
};

export const delay = (milliSecond: any) => new Promise(resolve => setTimeout(resolve, milliSecond));
export const dateToString = (date: any, format: string = 'DD/MM/YYYY') => {
  if (!dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format(format);
};
export const convertApplicableTime = (
  accessibility: TariffAccessibilityEnum,
  applicableTimeFrom: string | undefined,
  applicableTimeTo: string | undefined,
  display: boolean = true,
) => {
  if (!display) {
    return ' ';
  }
  if (accessibility === TariffAccessibilityEnum.Public)
    if (applicableTimeFrom && applicableTimeTo) {
      return `${dateToString(applicableTimeFrom)} - ${dateToString(applicableTimeTo)}`;
    } else return 'tariff.TariffRenewalHistory.table.accessibility.public.unlimited';
  return 'tariff.TariffRenewalHistory.table.accessibility.private';
};
export function formatVNDCurrency(money, intl: any) {
  if (isNaN(money)) {
    return ' ';
  }
  return intl.formatNumber(money) + 'USD';
}

export function getMeta(url, callback) {
  const img = new Image();
  img.src = url;
  img.onload = function (this, ev) {
    callback(img.width, img.height);
  };
}
export async function getThumbnailForVideo(videoUrl) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  // Trigger video load
  await new Promise<void>((resolve, reject) => {
    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener('seeked', () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas?.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return canvas.toDataURL('image/png');
}

export const secondToDuration = (seconds: number) => {
  if (seconds <= 0) {
    return '';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Format the values as two digits with leading zeros if necessary
  const formattedHours = ('0' + hours).slice(-2);
  const formattedMinutes = ('0' + minutes).slice(-2);
  const formattedSeconds = ('0' + remainingSeconds).slice(-2);

  return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
};
//take in a dayjs, return the diff from start of date to that moment in second
export const calculateDuration = (value: Dayjs | null): number => {
  if (!value) {
    return 0;
  }
  //input is dayjs object
  const startOfDay = value.startOf('date');
  return value.diff(startOfDay, 'second');
};
//take in start and end (dayjs object), return the diff of start end end in second
export const calcDiffSeconds = (start: Dayjs | null, end: Dayjs | null): number => {
  if (!start || !end) {
    return 0;
  }
  //input is dayjs object
  return end.diff(start, 'second');
};
//take in array of object {duration: number}, return string formatted 'hh:mm:ss'
export const calcSumDuration = (list: any): string => {
  if (lodash.isEmpty(list) || !lodash.isArray(list)) {
    return '00:00:00';
  }
  //input is dayjs object
  const sum = list.reduce((total, it) => {
    return total + it.duration || 0;
  }, 0);
  return secondToDuration(sum);
};
export function roundStringNumber(inputString, decimalPlaces) {
  if (inputString == null) return '';
  const decimalIndex = inputString.indexOf('.');
  if (decimalIndex !== -1) {
    return inputString.substring(0, decimalIndex + decimalPlaces + 1);
  } else {
    return inputString;
  }
}

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function toTimeFormat(seconds) {
  const duration = dayjs.duration(seconds, 'seconds');
  return duration.format('HH:mm:ss');
}

export function disabledHours(time: Dayjs) {
  const hours: number[] = [];
  if (!time) {
    return hours;
  }
  for (let i = 0; i < time.hour(); i++) {
    hours.push(i);
  }
  return hours;
}

export function disableMinutes(selectedHour: number, time: Dayjs) {
  const minutes: number[] = [];
  if (!selectedHour || !time) {
    return minutes;
  }

  if (selectedHour === time.hour()) {
    for (let i = 0; i <= time.minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
}

export function getAllMonthsBetweenDates(startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const months: any = [];
  const currentDate = startDate;

  // Loop through each month and add it to the 'months' array
  while (currentDate <= endDate && months.length < 32) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month is 0-indexed, so we add 1
    months.push(`${year}-${String(month).padStart(2, '0')}`); // Format the month as "YYYY-MM"

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
}

export function getLongestMonth(months) {
  let longestMonth = null;
  let longestDuration = 0;

  for (const month of months) {
    const startDate = dayjs(month);
    const endDate = startDate.endOf('month');
    const durationInDays = endDate.diff(startDate, 'day');

    if (durationInDays > longestDuration) {
      longestMonth = month;
      longestDuration = durationInDays;
    }
  }

  return longestMonth;
}

export function getAllDaysBetweenDates(startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const days: any = [];

  const currentDate = startDate;
  while (currentDate <= endDate) {
    days.push(currentDate?.getDate());
    currentDate.setDate(currentDate?.getDate() + 1);
  }

  return days;
}
//============================các hàm xử lí overlap schedule
//60-> 00:00:60
export const numberToTimeStringFormat = (seconds: number) => {
  if (isNaN(seconds)) {
    return;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Format the values as two digits with leading zeros if necessary
  const formattedHours = ('0' + hours).slice(-2);
  const formattedMinutes = ('0' + minutes).slice(-2);

  return formattedHours + ':' + formattedMinutes;
};

export function removeDuplicateItems(arr) {
  const seenIds = {};
  return arr?.filter(item => {
    if (!seenIds[item.id]) {
      seenIds[item.id] = true;
      return true;
    }
    return false;
  });
}

export const handleInputNumber = e => {
  if (e.key === '.' || e.key === ',' || e.key === '-' || e.key === '/') {
    e?.preventDefault();
  }
};

export function getListDateNotify(listNotify: NotificationsEntity[]) {
  const groupedByDay = {};
  listNotify.forEach(it => {
    const date = dayjs(it.createdAt).format('YYYY-MM-DD');
    if (groupedByDay[date]) {
      groupedByDay[date].push(it.createdAt);
    } else {
      groupedByDay[date] = [it.createdAt];
    }
  });
  return Object.keys(groupedByDay);
}
export const getListOfDate = (start: Dayjs, end: Dayjs): Dayjs[] => {
  //NAMTODO: validate
  const daysArray: Dayjs[] = [];
  let currentDate = start.startOf('day');
  while (currentDate.isBefore(end)) {
    daysArray.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }
  return daysArray;
};
//TÍnh x trong left: x,
export const calcAbsoluteLeftSchedule = (
  currentTime: Dayjs,
  RESOURCE_WIDTH: number,
  PIXEL_PER_TIMESLOT: number,
  viewMode: ViewModeEnum,
): number => {
  if (!currentTime.isValid() || isNaN(RESOURCE_WIDTH) || isNaN(PIXEL_PER_TIMESLOT)) return 0;
  const pixelPerSecond = PIXEL_PER_TIMESLOT / (viewMode === ViewModeEnum.Minute ? 60 : 3600);
  const startOfTheDateToCurrent =
    calcDiffSeconds(dayjs().startOf('day'), currentTime) * pixelPerSecond;
  return startOfTheDateToCurrent + RESOURCE_WIDTH;
};

// //Trả về thời điểm phát hoặc kết thúc gần currentTime nhất trong slotList, ko lấy trùng
// export const getClosestNextTime = (currentTime: Dayjs, slotList): Dayjs => {
//   const accumulator: Dayjs[] = [];
//   for (let i = 0; i < slotList.length; i++) {
//     if (slotList[i].startTime.isAfter(currentTime, 'second')) {
//       accumulator.push(slotList[i].startTime);
//     } else if (slotList[i].endTime.isAfter(currentTime, 'second')) {
//       accumulator.push(slotList[i].endTime);
//     }
//   }
//   let min = accumulator[0];
//   accumulator.forEach(current => {
//     if (current.isBefore(min, 'second')) min = current;
//   });
//   return min;
// };
//trả về thời gian kết thúc schedule cuối cùng
export const getLatestTime = (slotList): Dayjs => {
  let latest = slotList[0].endTime;
  for (let i = 1; i < slotList.length; i++) {
    if (slotList[i].endTime.isAfter(latest, 'second')) latest = slotList[i].endTime;
  }
  return latest;
};
//trả về thời gian diễn ra schedule đầu tiên
export const getEarliestTime = (slotList): Dayjs => {
  let earliest = slotList[0].startTime;
  for (let i = 1; i < slotList.length; i++) {
    if (slotList[i].startTime.isBefore(earliest, 'second')) earliest = slotList[i].startTime;
  }
  return earliest;
};
//trả về danh sách schedule có timeslot bao gọn từ startTime -> endTime , có lấy equal
export const getOverlapSchedules = (startTime, endTime, slotList) => {
  return slotList.filter(slot => {
    const isSameOrBefore =
      slot.startTime.isBefore(startTime, 'second') || slot.startTime.isSame(startTime, 'second');
    const isSameOrAfter =
      slot.endTime.isAfter(endTime, 'second') || slot.endTime.isSame(endTime, 'second');
    return isSameOrBefore && isSameOrAfter;
  });
};
//Check xem có schedule nào đang diễn ra tại currentTime hay ko
// export const checkHaveSchedule = (currentTime: Dayjs, schedules: ScheduleEntity[]): boolean => {
//   //NAMTODO: cần xem xét lại đoạn này vì releaseDate và finalDate ko biết là có lấy startOfDate và endOfDate ko, và nếu có thì là lấy của giờ Z hay local????(chắc là của local)
//   //Như vậy thì cái currentTime này nên cộng thêm 1s nhỉ?
//   return schedules.some(schedule => {
//     return checkIsBetween(currentTime, dayjs(schedule.releaseDate), dayjs(schedule.finalDate));
//   });
// };

//kiểm tra current có nằm giữa start và end, chỉ chấp nhận equal start
export const checkIsBetween_orStart = (current: Dayjs, start: Dayjs, end: Dayjs): boolean => {
  const isSameOrAfter = current.isAfter(start, 'second') || current.isSame(start, 'second');
  const isBefore = current.isBefore(end, 'second');

  return isBefore && isSameOrAfter;
};
//trả về ngày trong tuần, dùng -> formatMessage(handleConvertWeekDay(dayjs.format()))
export const handleConvertWeekDay = (day: number, language: string) => {
  const weeks_vi = {
    1: 'T2',
    2: 'T3',
    3: 'T4',
    4: 'T5',
    5: 'T6',
    6: 'T7',
    0: 'CN',
  };
  const weeks_en = {
    1: 'Mo',
    2: 'Tu',
    3: 'We',
    4: 'Th',
    5: 'Fr',
    6: 'Sa',
    0: 'Su',
  };
  if (language === 'vi') return weeks_vi[day];
  return weeks_en[day];
};

export function formatCurrencyWithComma(number) {
  const formattedNumber = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'USD',
    unitDisplay: 'narrow',
    notation: 'standard',
  }).format(number);
  return formattedNumber.replace(/\./g, ',');
}

//convert capacity display
export function formatCapacity(capacityArg: number, option?: boolean): any {
  let unit = 'bytes';
  let capacity = capacityArg;
  if (capacityArg >= 1024 * 1024 * 1024 * 1024 * 1024) {
    unit = 'PB';
    capacity /= 1024 * 1024 * 1024 * 1024 * 1024;
  } else if (capacityArg >= 1024 * 1024 * 1024 * 1024) {
    unit = 'TB';
    capacity /= 1024 * 1024 * 1024 * 1024;
  } else if (capacityArg >= 1024 * 1024 * 1024) {
    unit = 'GB';
    capacity /= 1024 * 1024 * 1024;
  } else if (capacityArg >= 1024 * 1024) {
    unit = 'MB';
    capacity /= 1024 * 1024;
  } else if (capacityArg >= 1024) {
    unit = 'KB';
    capacity /= 1024;
  }

  if (option != null && option === true) {
    return {
      capacity: capacity?.toFixed(2)?.replace(/\.00$/, ''),
      unit,
    };
  }

  return `${capacity?.toFixed(2)?.replace(/\.00$/, '')} ${unit}`;
}

//convert capacity display
export function formatCapacityInGB(
  capacityGB: number | null | undefined | any,
  notUseUnit?: boolean,
): string {
  if (capacityGB == null) return '';
  if (notUseUnit) return capacityGB?.toFixed(2)?.replace(/\.00$/, '');
  if (capacityGB >= 1024 * 1024) {
    return (capacityGB / (1024 * 1024))?.toFixed(2)?.replace(/\.00$/, '') + 'PB';
  } else if (capacityGB >= 1024) {
    return (capacityGB / 1024)?.toFixed(2)?.replace(/\.00$/, '') + 'TB';
  } else {
    return capacityGB?.toFixed(2)?.replace(/\.00$/, '') + 'GB';
  }
}
export function observeElementSize(refElement, callback) {
  const observer = new ResizeObserver(entries => {
    for (const entry of entries) {
      const { width, height } = entry.contentRect;
      callback(Math.floor(width), Math.floor(height));
    }
  });

  observer.observe(refElement);

  // Trả về một hàm để ngừng quan sát khi không cần thiết nữa
  return () => {
    observer.unobserve(refElement);
  };
}
export function hasQueryParameter(url) {
  const parsedUrl = new URL(url);
  return parsedUrl.search.length > 0;
}

export function base64Encode(input) {
  return btoa(unescape(encodeURIComponent(input)));
}
export function formatNumber(value: number | string | undefined): string {
  if (value === undefined || value === null) {
    return '';
  }

  let numberValue: number;

  if (typeof value === 'string') {
    numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return ''; // Return empty string for invalid numbers
    }
  } else {
    numberValue = value;
  }

  // Round to two decimal places
  const roundedValue = Math.round(numberValue * 100) / 100;

  // Convert to string and remove .00 if it's an integer
  return roundedValue.toString().replace(/\.00$/, '');
}

export const convertDataResponse = (res: any, entity?: any) => {
  return {
    data: entity ? entity(res?.pagedData) : res?.pagedData,
    info: new PaginationEntity(res?.pageInfo),
  };
};

export const getLocationName = async (latitude: number, longitude: number) => {
  const _latitude = latitude || 0;
  const _longitude = longitude || 0;
  const link = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${_latitude}&lon=${_longitude}
`;
  try {
    const response = await axios.get(link);
    if (response.data) {
      const address = response.data.address?.city || '';
      return address;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
