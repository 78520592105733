export default {
  'organizations.router.name': 'Tổ chức',
  'organizations.router.nameBreadcrumb': 'Quản lý tổ chức',
  'organizations.title': 'Danh sách tổ chức',
  'organizations.summary.totalOrganization': 'Tổng số tổ chức',
  'organizations.summary.totalFacility': 'Tổng số cơ sở',
  'organizations.filter.operationStatus': 'Trạng thái',
  'organizations.filter.operationStatus.unexpired': 'Còn hạn',
  'organizations.filter.operationStatus.expire': 'Hết hạn',
  'organizations.filter.operationStatus.cancelOrganization': 'Hủy tổ chức',
  'organizations.filter.operationStatus.notActivated': 'Chưa diễn ra',
  'organizations.filter.effectiveTime': 'Thời gian hiệu lực',
  'organizations.filter.expiredTime': 'Thời gian hết hạn',
  'organizations.table.code': 'Mã định danh tổ chức',
  'organizations.table.name': 'Tên tổ chức',
  'organizations.table.numberOfFacility': 'Số cơ sở trực thuộc',
  'organizations.table.capacityUsed': 'Gói cước gia hạn',
  'organizations.table.tariffPackage.periodByMonth': 'Thời hạn',
  'organizations.table.tariffPackage.effectiveTime': 'Thời gian hiệu lực',
  'organizations.table.tariffPackage.expiredTime': 'Thời gian hết hạn',
  'organizations.table.tariffPackage.numberOfDaysRemaining': 'Số ngày hết hạn',
  'organizations.table.operationStatus': 'Trạng thái',
  'organizations.table.operationStatus.params':
    '{status,select, 1 {Còn hạn} 2 {Hết hạn} 3 {Hủy tổ chức} 4 {Chưa diễn ra} other {Không xác định}}',
  'organizations.confirmDelete.title': 'Xác nhận xóa tổ chức',
  'organizations.confirmDelete.content': 'Tổ chức này sẽ bị xóa và không thể khôi phục.',
  'organizations.action.menu': 'Quản lý cơ sở',
  'organizations.action.file.expired': 'Gói cước ngưng kinh doanh',
  'organizations.action.file.title': 'Xác nhận gia hạn gói cước',
  'organizations.action.file.expired.content':
    'Không thể gia hạn vì gói cước {tariffPackagesName} đã {text}. Vui lòng lựa chọn gói cước khác để sử dụng',
  'organizations.action.file.expired.text': 'Ngưng kinh doanh',
  'organizations.action.file.content':
    'Gói cước {tariffPackagesName} sẽ được tiếp tục gia hạn từ {dateForm} đến {dateTo} với giá tiền là {price}.',
  'organizations.action.filePlus': 'Gia hạn gói cước',
  'organizations.action.close': 'Hủy tổ chức',
  'organizations.action.x.title': 'Xác nhận hủy bỏ tổ chức',
  'organizations.action.x.content':
    'Gói cước {packageName} sẽ bị hủy và dữ liệu sẽ bị xóa sau khi hủy bỏ tổ chức.',

  'organizations.modal.create.title': 'Thêm tổ chức',
  'organizations.modal.create.title.infoData': 'Thông tin gói cước sử dụng',
  'organizations.modal.create.title.infoOrganizations': 'Thông tin tổ chức',
  'organizations.modal.create.title.infoAccount': 'Thông tin tài khoản',
  'organizations.modal.create.form.code': 'Mã định danh tổ chức',
  'organizations.modal.create.form.code.required': 'Mã định danh tổ chức không đúng định dạng',
  'organizations.modal.create.form.code.placeholder': 'Vui lòng nhập mã định danh tổ chức',
  'organizations.modal.create.form.name': 'Tên tổ chức',
  'organizations.modal.create.form.name.placeholder': 'Vui lòng nhập tên định danh tổ chức',
  'organizations.modal.create.checkbox.data': 'Gói có sẵn',
  'organizations.modal.create.checkbox.notData': 'Gói khác',
  'organizations.modal.create.tariffPackagesSelect': 'Gói cước',
  'organizations.modal.create.tariffPackagesSelect.placeholder': 'Vui lòng chọn gói cước',
  'organizations.modal.create.capacity': 'Dung lượng',
  'organizations.modal.create.capacity.placeholder': 'Vui lòng nhập dung lượng',
  'organizations.modal.create.periodByMonth': 'Thời hạn',
  'organizations.modal.create.periodByMonth.month': 'Theo tháng',
  'organizations.modal.create.periodByMonth.unlimited': 'Không thời hạn',
  'organizations.modal.create.periodByMonth.placeholder': 'Vui lòng nhập thời hạn',
  'organizations.modal.create.effectiveTime': 'Thời gian hiệu lực',
  'organizations.modal.create.expiredTime': 'Thời gian hết hạn',
  'organizations.modal.create.price': 'Giá tiền',
  'organizations.modal.create.price.placeholder': 'Vui lòng nhập giá tiền',
  'organizations.modal.create.account.name': 'Họ và tên',
  'organizations.modal.create.account.name.placeholder': 'Vui lòng nhập họ và tên',
  'organizations.modal.create.account.email': 'Email',
  'organizations.modal.create.account.email.required': 'Email không đúng định dạng',
  'organizations.modal.create.account.email.placeholder': 'Vui lòng nhập email',
  'organizations.modal.create.account.timeZone': 'Múi giờ',
  'organizations.modal.create.account.timeZone.placeholder': 'Vui lòng chọn múi giờ',
  'organizations.modal.create.account.phoneNumber': 'Số điện thoại',
  'organizations.modal.create.account.phoneNumber.placeholder': 'Vui lòng nhập số điện thoại',
  'organizations.modal.create.account.dayOfBirth': 'Ngày sinh',
  'organizations.modal.create.account.dayOfBirth.placeholder': 'Vui lòng nhập ngày sinh',
  'organizations.modal.create.account.username': 'Tên đăng nhập',
  'organizations.modal.create.account.username.placeholder': 'Vui lòng nhập tên đăng nhập',
  'organizations.modal.create.account.password': 'Mật khẩu',
  'organizations.modal.create.account.password.placeholder': 'Vui lòng nhập mật khẩu',
  'organizations.modal.create.account.password.required':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'organizations.router.info.name': 'Thông tin tổ chức',
  'organizations.info.title': 'Thông tin tổ chức',
  'organizations.info.title.infoOrganizations': 'Thông tin tổ chức',
  'organizations.info.code': 'Mã định danh tổ chức',
  'organizations.info.name': 'Tên tổ chức',
  'organizations.info.operationStatus': 'Trạng thái',
  'organizations.info.operationStatus.params':
    '{status,select, 1 {Còn hạn} 2 {Hết hạn} 3 {Hủy tổ chức} 4 {Chưa diễn ra} other {Không xác định}}',
  'organizations.info.capacity': 'Dung lượng sử dụng',
  'organizations.info.title.infoAccount': 'Thông tin tài khoản',
  'organizations.info.account.name': 'Họ và tên',
  'organizations.info.account.username': 'Tên đăng nhập',
  'organizations.info.account.email': 'Email',
  'organizations.info.account.phoneNumber': 'Số điện thoại',
  'organizations.info.account.dayOfBirth': 'Ngày sinh',
  'organizations.info.account.timeZone': 'Múi giờ',
  'organizations.info.title.packageInfo': 'Thông tin gói cước',
  'organizations.info.title.history.transaction': 'Lịch sử giao dịch',
  'organizations.info.filter.extensionTime': 'Thời gian gia hạn',
  'organizations.paymentHistories.table.gracetime': 'Thời gian gia hạn',
  'organizations.paymentHistories.table.tariffPackage.capacity': 'Dung lượng',
  'organizations.paymentHistories.table.organization.tariffPackage.effectiveTime':
    'Thời gian hiệu lực',
  'organizations.paymentHistories.table.effectiveTime': 'Thời gian hiệu lực',
  'organizations.paymentHistories.table.price': 'Giá gói cước',
  'organizations.paymentHistories.table.payer.name': 'Người gia hạn',
  'organizations.paymentHistories.table.payer.phoneNumber': 'Số điện thoại',
  'organizations.paymentHistories.table.paymentStatus': 'Trạng thái',
  'organizations.paymentHistories.table.paymentMethod': 'Phương thức',
  'organizations.paymentHistories.table.paymentStatus.params':
    '{status,select, 1 {Thành công} 2 {Đang xử lý} 3 {Thất bại} 4 {Đã hủy} other {Khác}}',
  'organizations.paymentHistories.table.paymentMethod.params':
    '{status,select, 1 {Trực tiếp} 2 {Ví điện tử} 3 {VNPay} 4 {--} 5 {PayPal} 6 {OnePay} other {Khác}}',
  'organizations.info.action.x.title': 'Xác nhận hủy gói cước thay đổi',
  'organizations.info.action.x.content': 'Gói cước thay đổi {packageName}/{month} sẽ bị hủy bỏ.',
  'organizations.info.businessStatus.outOfBusiness': 'Ngưng kinh doanh',
  'organizations.info.paymentStatus.processing': 'Đang xử lí',
  'organizations.info.modal.change.add.title': 'Thêm gói cước mới',
  'organizations.info.modal.change.update.title': 'Thay đổi gói cước',
  'organizations.info.modal.change.checkbox.data': 'Gói có sẵn',
  'organizations.info.modal.change.checkbox.notData': 'Gói khác',
  'organizations.info.modal.change.tariffPackagesSelect': 'Gói cước',
  'organizations.info.modal.change.tariffPackagesSelect.placeholder': 'Vui lòng chọn gói cước',
  'organizations.info.modal.change.capacity': 'Dung lượng',
  'organizations.info.modal.change.capacity.placeholder': 'Vui lòng nhập dung lượng',
  'organizations.info.modal.change.account.periodByMonth': 'Thời hạn',
  'organizations.info.modal.change.account.periodByMonth.month': 'Theo tháng',
  'organizations.info.modal.change.account.periodByMonth.unlimited': 'Không thời hạn',
  'organizations.info.modal.change.account.periodByMonth.placeholder': 'Vui lòng nhập thời hạn',
  'organizations.info.modal.change.account.effectiveTime': 'Thời gian hiệu lực',
  'organizations.info.modal.change.account.expiredTime': 'Thời gian hết hạn',
  'organizations.info.modal.change.account.price': 'Giá tiền',
  'organizations.info.modal.change.account.price.placeholder': 'Vui lòng nhập giá tiền',
  'organizations.info.modal.change.render.total': 'Tổng thực thu {total}',
  'organizations.info.modal.update.title': 'Cập nhật tổ chức',
  'organizations.info.modal.update.title.infoOrganizations': 'Thông tin tổ chức',
  'organizations.info.modal.update.form.code': 'Mã định danh tổ chức',
  'organizations.info.modal.update.form.code.required': 'Mã định danh tổ chức không đúng định dạng',
  'organizations.info.modal.update.form.code.placeholder': 'Vui lòng nhập mã định danh tổ chức',
  'organizations.info.modal.update.form.name': 'Tên tổ chức',
  'organizations.info.modal.update.form.name.placeholder': 'Vui lòng nhập tên định danh tổ chức',
  'organizations.info.modal.update.form.isAutoRenewal': 'Gia hạn tự động',
  'organizations.info.modal.update.form.isAutoRenewal.yes': 'Đồng ý',
  'organizations.info.modal.update.form.isAutoRenewal.no': 'Không đồng ý',
  'organizations.info.modal.update.title.infoAccount': 'Thông tin tài khoản',
  'organizations.info.modal.update.account.name': 'Họ và tên',
  'organizations.info.modal.update.account.name.placeholder': 'Vui lòng nhập họ và tên',
  'organizations.info.modal.update.account.email': 'Email',
  'organizations.info.modal.update.account.email.placeholder': 'Vui lòng nhập email',
  'organizations.info.modal.update.account.timeZone': 'Múi giờ',
  'organizations.info.modal.update.account.timeZone.placeholder': 'Vui lòng chọn múi giờ',
  'organizations.info.modal.update.account.phoneNumber': 'Số điện thoại',
  'organizations.info.modal.update.account.phoneNumber.placeholder': 'Vui lòng nhập số điện thoại',
  'organizations.info.modal.update.account.dayOfBirth': 'Ngày sinh',
  'organizations.info.modal.update.account.dayOfBirth.placeholder': 'Vui lòng nhập ngày sinh',
  'organizations.info.modal.update.account.username': 'Tên đăng nhập',
  'organizations.info.modal.update.account.username.placeholder': 'Vui lòng nhập tên đăng nhập',
};
